
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import CommonInfobutton from "@/components/common/Infobutton.vue";

@Component({
  components: {
    CommonInfobutton,
  }
})
export default class Main extends Mixins(mixinNencho) {
  @Prop() index: number;

  get tmp_prev_payment() {
    return this.m.prev_payments[this.index];
  }
}
