
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import Earning from "@/entities/Earning";
import CommonInfobutton from "@/components/common/Infobutton.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonInfobutton,
    CommonButton,
  }
})
export default class Main extends Mixins(mixinNencho) {
  add_prev_payment() {
    this.m.setPrevPayment({
      index: this.m.prev_payment_num,
      prev_payment: {
        id: null,
        nencho_id: null,
        pay_money: null,
        social_money: null,
        tax_money: null,
        retired_on: null,
        address: null,
        name: null,
      }
    });
    this.m.setPrevPaymentNum({num: this.m.prev_payment_num + 1});
  }
}
