
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import { validationPrevPayment } from '@/validations/nencho_prev_payment';
import validate from '@/validations/validate';
import validate_api from "@/validations/validate_api";
import CommonProgress from "@/components/common/Progress.vue";
import { VStepper } from "vuetify/lib";
import { VStepperHeader } from "vuetify/lib";
import { VStepperStep } from "vuetify/lib";
import { VDivider } from "vuetify/lib";
import { VStepperItems } from "vuetify/lib";
import { VStepperContent } from "vuetify/lib";
import CommonBigbutton from "@/components/common/Bigbutton.vue";
import NenchoPrevpaymentPaymentNuminput from "@/components/nencho/prevpayment/payment/Numinput.vue";
import NenchoPrevpaymentPaymentInput from "@/components/nencho/prevpayment/payment/Input.vue";
import NenchoPrevpaymentPaymentNumdisplay from "@/components/nencho/prevpayment/payment/Numdisplay.vue";
import NenchoPrevpaymentPaymentDisplay from "@/components/nencho/prevpayment/payment/Display.vue";


@Component({
  components: {
    CommonProgress,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VDivider,
    VStepperItems,
    VStepperContent,
    CommonBigbutton,
    NenchoPrevpaymentPaymentNuminput,
    NenchoPrevpaymentPaymentInput,
    NenchoPrevpaymentPaymentNumdisplay,
    NenchoPrevpaymentPaymentDisplay,
  }
})
export default class Main extends Mixins(mixinNencho) {
  save = false;

  update_nencho_prev_payment() {
    this.m.updateNenchoPrevPayment({nencho_id: this.nencho_id, employee_id: this.employee_id, query: this.query});
  }

  get is_finish_prev_payment_query() {
    return this.query.is_finish_prev_payment;
  }

  @Watch('is_finish_prev_payment_query')
  scroll_top(newval, oldval) {
    if (newval !== undefined && oldval === undefined) {
      this.scrollTop();
    }
  }


  get step_number() {
    return this.prev_payment_step_number;
  }

  get step() {
    return this.m.step;
  }
  set step(val) {
    this.m.setStep(val);
  }

  async next_step(step) {
    if (step < this.step) {
      this.step = step
    } else if (this.step_number['payment'] && this.step == this.step_number['payment']['step']) {
      var error = [];
      if (this.m.prev_payment_num == 1) {
        error = error.concat(validationPrevPayment(this.m.prev_payments[0], 0));
      } else {
        for (var index = 0; index < this.m.prev_payment_num; index++) {
          error = error.concat(validationPrevPayment(this.m.prev_payments[index], index + 1));
        }
      }
      if (validate(error)) {
        this.step = step;
      }
    } else {
      this.step = step;
    }
  }

  save_nencho() {
    this.m.setSave({nencho_id: this.nencho_id, employee_id: this.employee_id, kind: 'prev_payment', content: {
      prev_payments: this.m.prev_payments,
      step: this.m.step,
    }});
  }

  async created() {
    this.save = false;
    if (this.query.is_finish_prev_payment_query === undefined) {
      this.m.setStep(0.1);
      if (this.employee_yearly.nencho_master.is_finish_prev_payment == 1) {
        this.save = true;
        this.m.setPrevPaymentNum({num: this.prev_payment_yearly.prev_payments.length});
        this.m.setPrevPayments({prev_payments: this.prev_payment_yearly.prev_payments});
        this.m.setStep(this.step_number['confirm']['step']);
      } else {
        this.save = await this.m.getSave({nencho_id: this.nencho_id, employee_id: this.employee_id, kind: 'prev_payment'});
        if (this.save !== null) {
          this.m.setPrevPaymentNum({num: this.save.content.prev_payments.length});
          this.m.setPrevPayments({prev_payments: this.save.content.prev_payments});
          this.m.setStep(this.save.content.step);
        } else {
          this.m.setPrevPaymentNum({num: 0});
          this.m.setPrevPayments({prev_payments: []});
        }
      }
    } else {
      this.save = true;
    }
  }
}
