
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import CommonButton from "@/components/common/Button.vue";
import CommonInfobutton from "@/components/common/Infobutton.vue";
import CommonText from "@/components/common/Text.vue";
import CommonDate from "@/components/common/Date.vue";

@Component({
  components: {
    CommonButton,
    CommonInfobutton,
    CommonText,
    CommonDate,
  }
})
export default class Main extends Mixins(mixinNencho) {
  @Prop() index: number;


  get tmp_prev_payment() {
    return this.m.prev_payments[this.index];
  }
  set tmp_prev_payment(val) {
    this.m.setPrevPayment({index: this.index, prev_payment: val});
  }

  delete_prev_payment() {
    var index = this.m.prev_payment_num > 1 ? (this.index + 1) : '';
    dialog.openConfirmDialog({msg: '前職分の給与等'+index+'を削除してよろしいですか？', cancel_button: '削除しない', exec_button: '削除する', func: this.delete_prev_payment_process, args: {}});
  }
  delete_prev_payment_process({}) {
    this.m.setPrevPaymentNum({num: this.m.prev_payment_num - 1});
    this.m.deletePrevPayment({index: this.index});
  }
}
