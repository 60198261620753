import * as util from "@/util";
import * as vali from "@/validations/validationUtil";


export function validationPrevPayment(prev_payment, index = null) {
  var error = [];

  var prefix = '';
  if (index === 0) {
    prefix = '前職分の給与等の';
  } else if (index > 0) {
    prefix = '前職分の給与等'+index+'の';
  }

  error.push(prev_payment.pay_money !== null || prefix+'支払金額を入力してください');
  error.push(prev_payment.pay_money === null || vali.is_number(prev_payment.pay_money) || prefix+'支払金額は半角数字で入力してください');
  error.push(prev_payment.social_money !== null || prefix+'社会保険料等の金額を入力してください');
  error.push(prev_payment.social_money === null || vali.is_number(prev_payment.social_money) || prefix+'社会保険料等の金額は半角数字で入力してください');
  error.push(prev_payment.tax_money !== null || prefix+'源泉徴収税額を入力してください');
  error.push(prev_payment.tax_money === null || vali.is_number(prev_payment.tax_money) || prefix+'源泉徴収税額は半角数字で入力してください');

  return error;
}